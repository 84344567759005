<template>
  <b-card no-body class="card-custom mb-3">
    <template #header>
      <div v-if="user.hasPermission('rtc:channel.create')">
        <b-button class="float-right" size="sm" variant="primary" v-b-modal.modal-channel>
          <BIconPlus/> New Channel
        </b-button>
        <b-modal ref="modalChannel" id="modal-channel" title="Create channel" hide-footer>
            <ChannelForm ref="channelForm" @channel-created="channelCreated"/>
        </b-modal>
      </div>
      <h6 class="mb-0 pt-1">My Channels</h6>
    </template>
    <b-card-text>
      <div class="table-custom">
        <b-table v-if="!channels.isLoading" responsive hover :items="channels.records" :fields="channels.fields" empty-text="no channels" show-empty>
          <template #cell(origins)="data">
            <b-link v-if="!data.item.origins" size="sm" :to="{name: 'callcenter.channel.origins', params: {channelId: data.item.id}}">add origin</b-link>
            <span v-else>{{data.item.origins.length}} origin(s)</span>
          </template>
          <template #cell(options)="data">
            <div class="text-right">
              <b-link v-b-tooltip.hover title="View Board" :to="{name: 'channel.board', params: {channelId: data.item.id}}" class="mr-3"><BIconBroadcast/></b-link>
              <b-link v-if="user.hasPermission('rtc:channel.findBy.id')" v-b-tooltip.hover title="Manage" :to="{name: 'callcenter.channel', params: {channelId: data.item.id}}"><BIconPencilSquare/></b-link>
            </div>
          </template>
        </b-table>
        <div v-else style="padding: 20px;">
          <b-spinner label="Loading..." small></b-spinner>
        </div>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {BIconPlus,BIconPencilSquare,BIconBroadcast} from 'bootstrap-vue';
import ChannelForm from './../../components/Channel.Form';
export default {
    props: ['user'],
    components: {
      BIconPlus,BIconPencilSquare,BIconBroadcast,
      ChannelForm
    },
    data(){
        return {
          channels: {
            isLoading: false,
            fields: [],
            records: [],
            total: 0,
            pagination: {
              currentPageNo: 1
            }
          }
        };
    },

    methods: {
      getFields(){
        let fields = [
          {key: 'id', label: "ID"},
          {key: 'name',label: "Name"}
        ];

        if(this.user.hasPermission('rtc:channel.update')){
          fields.push({key: 'origins',label: "Origins"});
        }

        fields.push({key: 'options',label: ""});
        return fields;
      },
      channelCreated(channel){
        this.$refs['modalChannel'].hide();
        this.getChannels().then(() => {
          let channels = this.$store.getters['Callcenter/getChannels'];
          channels.push(Object.assign(channel,{
            clients: [],
            users: []
          }));

          this.$store.dispatch('Callcenter/addChannels', channels);
          this.$us.signal.registerChannels([channel.id]);
        }).catch(() => {console.log('no channels fetched')});
      },
      getChannels(){
        this.channels.isLoading = true;
        return this.$api.get('channels',{params: {perPage: 12}}).then(res => {
          this.channels.isLoading = false;
          let {records, pagination, total} = res.data;
          this.channels.records = records;
          this.channels.pagination = pagination;
          this.channels.total = total;
          this.channels.fields = this.getFields();
        }).catch(() => {
          console.log('cannot fetch channels');
          this.channels.isLoading = false;
        });
      }
    },

    mounted(){
      this.getChannels();
    }
};
</script>
