<template>
    <FormTemplate v-if="form" :form="form">
      <div class="form-group">
          <label class="label-for-xl" for="inputName">Name</label>
          <b-form-input
              type="text" id="inputName" class="form-control form-control-xl"
              placeholder="enter channel name"
              v-model="form.data.name"
              :state="form.states.name"
          />
          <b-form-invalid-feedback v-if="form.errors">{{ form.errors.name }}</b-form-invalid-feedback>
      </div>
      <template v-slot:buttons>
        <div class="border-top mt-3 pt-3 text-right">
          <button type="submit" class="btn btn-xl btn-primary" :disabled="form.submitButton.disabled">
              <b-spinner small v-if="form.submitButton.disabled"></b-spinner>
              <span class="sr-only" v-if="form.submitButton.disabled">Loading...</span>
              <span  v-if="!form.submitButton.disabled">
                  Create
              </span>
          </button>
        </div>
      </template>
    </FormTemplate>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';

export default {
    components: {
        FormTemplate
    },
    data(){
        return {
            form: null
        };
    },
    mounted(){
        this.form = new Form({
            name: "",
        },{
            onSubmit: (form) => {
                return this.$api.post('channel', {
                    data: {name: form.get('name')}
                })
            },
            onSuccess: (form, {data}) => {
              this.$emit('channel-created', data);
            },
            onError: function(form, err){
                console.log(err);
            }
        });
    }
};
</script>
